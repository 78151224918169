<template>
  <div id="page-top">
    <!-- Navigation-->
    <nav class="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav">
        <div class="container px-4 px-lg-5">
            <a class="navbar-brand" href="#page-top">Helios</a>
            <button class="navbar-toggler navbar-toggler-right" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                <!-- <i class="fas fa-bars"></i> -->
                <feather-icon
                    icon="MenuIcon"
                    size="18"
                />
                Menu
            </button>
            <div class="collapse navbar-collapse" id="navbarResponsive">
                <ul class="navbar-nav ms-auto">
                    <li class="nav-item"><a class="nav-link" href="#about">Nosotros</a></li>
                    <li class="nav-item"><a class="nav-link" href="#services">Servicios</a></li>
                    <li class="nav-item"><a class="nav-link" href="#contact">Contacto</a></li>
                    <li class="nav-item"><a class="nav-link" href="/" target="_blank">Dashboard</a></li>
                </ul>
            </div>
        </div>
    </nav>
    <!-- Masthead-->
    <header class="masthead">
        <div class="container px-4 px-lg-5 d-flex h-100 align-items-center justify-content-center">
            <div class="d-flex justify-content-center">
                <div class="text-center">
                    <h1 class="mx-auto my-0 text-uppercase">Helios</h1>
                    <h2 class="text-white-50 mx-auto mt-2 mb-5">Reciclaje de chatarra haciendo uso de residuos industriales, cuidando al medio ambiente.</h2>
                    <a class="btn btn-primary" href="#about">Conoce más</a>
                </div>
            </div>
        </div>
    </header>
    <!-- About-->
    <section class="about-section text-center" id="about">
        <div class="container px-4 px-lg-5">
            <div class="row gx-4 gx-lg-5 justify-content-center">
                <div class="col-lg-8">
                    <h2 class="text-white mb-4">Compra - Venta de chatarra de acero y metales diversos</h2>
                    <p class="text-white-50">
                        Comprometidos a fomentar la prevención de la contaminación a través
                        de la recolección y el reciclaje de desperdicios industriales.
                    </p>
                </div>
            </div>
            <img class="img-fluid" src="./assets/img/scrap2.png" alt="..." />
        </div>
    </section>
    <!-- Services-->
    <section class="projects-section bg-light" id="services">
      <div class="container px-4 px-lg-5">
            <!-- Featured Project Row-->
            <div class="row gx-0 mb-4 mb-lg-5 align-items-center">
                <div class="col-xl-8 col-lg-7"><img class="img-fluid mb-3 mb-lg-0" src="./assets/img/bg-masthead.jpg" alt="..." /></div>
                <div class="col-xl-4 col-lg-5">
                    <div class="featured-text text-center text-lg-left">
                        <h4>Misión</h4>
                        <p class="text-black-50">Somos una empresa especializada, con más de 9 meses de experiencia, dedicada a proporcionar servicios integrales en la compra y venta de materiales ferrosos y no ferrosos, ofreciendo soluciones confiables a todo tipo de compañías mexicanas o extranjeras que deseen comprar o vender los desperdicios generados en su producción.</p>
                        <br>
                        <h4>Visión</h4>
                        <p class="text-black-50">Aprovechamos al máximo sus desperdicios industriales de operación y producción, contribuyendo con el medio ambiente, al reciclar y reutilizar el metal, simplificándole el trato con una sola empresa proveedora.</p>
                    </div>
                </div>
            </div>
            <br>
            <!-- Metal Three Row-->
            <div class="row gx-0 mb-5 mb-lg-0 justify-content-center">
                <div class="col-lg-6"><img class="img-fluid" src="./assets/img/placa.jpeg" alt="..." /></div>
                <div class="col-lg-6">
                    <div class="bg-black text-center h-100 project">
                        <div class="d-flex h-100">
                            <div class="project-text w-100 my-auto text-center text-lg-left">
                                <h4 class="text-white">Acero</h4>
                                <p class="mb-0 text-white-50">Placa</p>
                                <p class="mb-0 text-white-50">Fierro Gris</p>
                                <hr class="d-none d-lg-block mb-0 ms-0" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Cobre Two Row-->
            <div class="row gx-0 justify-content-center">
                <div class="col-lg-6"><img class="img-fluid" src="./assets/img/copper-scrap.jpg" alt="..." /></div>
                <div class="col-lg-6 order-lg-first">
                    <div class="bg-black text-center h-100 project">
                        <div class="d-flex h-100">
                            <div class="project-text w-100 my-auto text-center text-lg-right">
                                <h4 class="text-white">Cobre</h4>
                                <p class="mb-0 text-white-50">Cobre Brillante</p>
                                <p class="mb-0 text-white-50">Cobre 1a</p>
                                <p class="mb-0 text-white-50">Cobre 2da</p>
                                <p class="mb-0 text-white-50">Bronce</p>
                                <p class="mb-0 text-white-50">Cobre Estañado</p>
                                <hr class="d-none d-lg-block mb-0 me-0" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Aluminio Row-->
            <div class="row gx-0 mb-5 mb-lg-0 justify-content-center">
                <div class="col-lg-6"><img class="img-fluid" src="./assets/img/aluminum-scrap.jpg" alt="..." /></div>
                <div class="col-lg-6">
                    <div class="bg-black text-center h-100 project">
                        <div class="d-flex h-100">
                            <div class="project-text w-100 my-auto text-center text-lg-left">
                                <h4 class="text-white">Aluminio</h4>
                                <p class="mb-0 text-white-50">UBC</p>
                                <p class="mb-0 text-white-50">DELGADO</p>
                                <p class="mb-0 text-white-50">GRUESO</p>
                                <p class="mb-0 text-white-50">PERFIL 6061</p>
                                <p class="mb-0 text-white-50">PERFIL 6063</p>
                                <p class="mb-0 text-white-50">INOX 304</p>
                                <p class="mb-0 text-white-50">INOX 316</p>
                                <hr class="d-none d-lg-block mb-0 ms-0" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Magnesio Four Row-->
            <!-- <div class="row gx-0 justify-content-center">
                <div class="col-lg-6"><img class="img-fluid" src="./assets/img/magnesium-scrap.jpg" alt="..." /></div>
                <div class="col-lg-6 order-lg-first">
                    <div class="bg-black text-center h-100 project">
                        <div class="d-flex h-100">
                            <div class="project-text w-100 my-auto text-center text-lg-right">
                                <h4 class="text-white">Magnesio</h4>
                                <hr class="d-none d-lg-block mb-0 me-0" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
      </div>
    </section>
    <!-- Contact-->
    <section id="contact" class="contact-section bg-black">
        <div class="container px-4 px-lg-5">
            <div class="row gx-4 gx-lg-5">
                <div class="col-md-4 mb-3 mb-md-0">
                    <div class="card py-4 h-100 bg-white">
                        <div class="card-body text-center text-black">
                            <!-- <i class="fas fa-map-marked-alt mb-2"></i> -->
                            <feather-icon
                              icon="MapPinIcon"
                              size="20"
                              class="contact-icon"
                            />
                            <h4 class="text-uppercase m-0 text-black">Dirección</h4>
                            <hr class="my-4 mx-auto" />
                            <div class="small text-black-50">4923 Market Street, Orlando FL</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mb-3 mb-md-0">
                    <div class="card py-4 h-100 bg-white">
                        <div class="card-body text-center text-black">
                            <!-- <i class="fas fa-envelope mb-2"></i> -->
                            <feather-icon
                              icon="MailIcon"
                              size="20"
                              class="contact-icon"
                            />
                            <h4 class="text-uppercase m-0 text-black">Correo</h4>
                            <hr class="my-4 mx-auto" />
                            <div class="small text-black-50"><a href="#!">contacto@helios-reciclaje.com</a></div>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 mb-3 mb-md-0">
                    <div class="card py-4 h-100 bg-white">
                        <div class="card-body text-center text-black">
                            <!-- <i class="fas fa-mobile-alt mb-2"></i> -->
                            <feather-icon
                              icon="SmartphoneIcon"
                              size="20"
                              class="contact-icon"
                            />
                            <h4 class="text-uppercase m-0 text-black">Teléfono</h4>
                            <hr class="my-4 mx-auto" />
                            <div class="small text-black-50">+52 81 1607 9270</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="social d-flex justify-content-center">
                <a class="mx-2" href="#!"><feather-icon icon="TwitterIcon" size="20" class="mx-2"/></a>
                <a class="mx-2" href="#!"><feather-icon icon="FacebookIcon" size="20" class="mx-2"/></a>
                <a class="mx-2" href="#!"><feather-icon icon="GithubIcon" size="20" class="mx-2"/></a>
            </div>
        </div>
    </section>
    <!-- Footer-->
    <footer class="footer bg-black small text-center text-white-50"><div class="container px-4 px-lg-5">Copyright &copy; Helios 2022</div></footer>
    <!-- Bootstrap core JS-->
    <!-- <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js"></script> -->
    <!-- Core theme JS-->
    <!-- <script src="js/scripts.js"></script> -->
    <!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *-->
    <!-- * *                               SB Forms JS                               * *-->
    <!-- * * Activate your form at https://startbootstrap.com/solution/contact-forms * *-->
    <!-- * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *-->
    <!-- <script src="https://cdn.startbootstrap.com/sb-forms-latest.js"></script> -->
  </div>
</template>


<style lang="scss" scoped>
  @import './Landing.css';
</style>


<script>
/* eslint-disable global-require */
import { contentLoaded } from './scripts.js';
import './Bootstrap2.js';
import './Bootstrap1.js';

export default {
  components: {
  },
  data() {
    return {
    }
  },
  created() {
    contentLoaded();
  },
  computed: {
  },
  methods: {
  },
}
</script>
